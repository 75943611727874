<template>
    <div
        id="modal-change-struct"
        role="dialog"
        v-show="isModalLoaded"
        class="modal-change-struct"
    >
        <vue-progress-bar />
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="block-title" id="title_struct">
                        {{ title }} отдела
                    </h3>
                    <div class="block-options">
                        <button
                            type="button"
                            class="close-modal"
                            @click="$modal.hide('CreateStructure')"
                        >
                            ×
                        </button>
                    </div>
                </div>
                <ValidationObserver
                    tag="form"
                    @submit.prevent="onSubmit"
                    ref="observer"
                >
                    <div class="modal-body">
                        <ValidationProvider
                            tag="div"
                            class="form-group"
                            id="id_name_form"
                            rules="required"
                            v-slot="{ errors }"
                            vid="name"
                            slim
                        >
                            <div class="form-material floating open">
                                <input
                                    type="text"
                                    name="name"
                                    id="id_name"
                                    v-model="form.name"
                                    class="form-control valid"
                                    maxlength="255"
                                />
                                <label for="id_name">Название отдела *</label>
                                <div
                                    id="form_fio-error"
                                    class="v-error animated fadeInDown"
                                    v-if="errors.length > 0"
                                >
                                    {{ errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            tag="div"
                            class="form-group"
                            v-slot="provider"
                            rules="required"
                            vid="parent"
                        >
                            <div class="form-material floating open">
                                <v-select2
                                    v-model="form.parent"
                                    id="id_parent"
                                    class="form-material mt-10"
                                    :options="structures"
                                    :reduce="label => label.id"
                                    label="name"
                                    :clearable="false"
                                    :searchable="true"
                                >
                                    <span slot="no-options"
                                        >Ничего не найдено</span
                                    >
                                </v-select2>
                                <label class="" for="id_parent"
                                    >Подчиняется отделу</label
                                >
                                <div
                                    class="v-error animated fadeInDown"
                                    v-if="provider.errors.length > 0"
                                >
                                    {{ provider.errors[0] }}
                                </div>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            tag="div"
                            class="form-group"
                            v-if="isEditing"
                            v-slot="provider"
                            vid="staff"
                        >
                            <div class="form-material floating open">
                                <v-select2
                                    v-model="form.chiefs"
                                    id="id_staff"
                                    class="form-material mt-10"
                                    :reduce="label => label"
                                    :options="chiefs"
                                    label="full_name"
                                    :clearable="false"
                                    :searchable="true"
                                >
                                    <span slot="no-options"
                                        >Ничего не найдено</span
                                    >
                                </v-select2>
                                <label class="" for="id_staff">
                                    Начальник отдела
                                    <span
                                        class="modal-info-btn"
                                        v-tooltip="{
                                            content:
                                                'Может выписывать премии и штрафы сотрудникам своего и всех дочерних отделов.',
                                            offset: 7
                                        }"
                                    >
                                        (i)
                                    </span>
                                </label>
                            </div>
                            <div
                                class="v-error animated fadeInDown"
                                v-if="provider.errors.length > 0"
                            >
                                {{ provider.errors[0] }}
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="modal-footer">
                        <a
                            href="javascript:void(0)"
                            class="material-footer__reset"
                            @click="$modal.hide('CreateStructure')"
                            >Отмена</a
                        >
                        <button
                            type="submit"
                            class="material-footer__add"
                            :disabled="isFormSending"
                        >
                            Сохранить
                        </button>
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
    import session from '@/api/session'

    export default {
        name: 'CreateStructure',
        props: {
            struct_id: {
                type: Number,
                required: false
            },
            parent: {
                type: Object,
                required: false
            },
            loadData: {
                type: Function
            },
            onCreate: {
                type: Function
            },
            onEdit: {
                type: Function
            },
            expandHandler: {
                type: Function
            }
        },
        data() {
            return {
                structures: [],
                chiefs: [],
                form: {},
                title: this.struct_id ? 'Редактирование' : 'Добавление',
                isFormSending: false,
                isEditing: !!this.struct_id,
                isModalLoaded: false
            }
        },
        methods: {
            async onSubmit() {
                this.isFormSending = true
                try {
                    let isValid = await this.$refs.observer.validate()
                    if (isValid) {
                        this.$Progress.start()
                        if (!this.form.staff) {
                            this.form.staff = ''
                        }
                        if (this.isEditing) {
                            const request = await session.put(
                                `/api/v1/structure/${this.struct_id}/`,
                                {
                                    ...this.form,
                                    chiefs: Array.isArray(this.form.chiefs)
                                        ? this.form.chiefs.map(
                                              chief => chief.id
                                          )
                                        : [this.form.chiefs.id]
                                }
                            )
                            this.onEdit({
                                ...request.data,
                                old_parent: this.oldParent
                            })
                        } else {
                            const request = await session.post(
                                `/api/v1/structure/`,
                                this.form
                            )
                            this.onCreate(request.data, this.expandHandler)
                        }

                        this.$Progress.finish()

                        this.$modal.hide('CreateStructure')
                        await this.$swal({
                            title: 'Отлично!',
                            text: this.isEditing
                                ? 'Данные изменены'
                                : 'Отдел добавлен',
                            icon: 'success',
                            showConfirmButton: false,
                            timer: 1400
                        })
                        // window.location.reload();
                    }
                } catch (error) {
                    console.error(error)
                    this.$refs.observer.setErrors(error.response.data)
                    this.$Progress.finish()
                }
                this.isFormSending = false
            }
        },
        async mounted() {
            try {
                /* Добавление списка отделов в селект */
                const apiAddition = this.isEditing
                    ? `?structure_id=${this.struct_id}`
                    : ''
                const defaultData = await session.get(
                    `/api/v1/structure/default_form/${apiAddition}`
                )
                this.structures = defaultData.data.structure
                this.chiefs = defaultData.data.users

                /* Открытие окна через кнопку редактирования отдела
                 * Если компонент получает id, то добавляем отдел в форму */
                if (this.isEditing) {
                    /* Получение данных принимаемого отдела */
                    let structData = await session.get(
                        `/api/v1/structure/${this.struct_id}/`
                    )
                    this.oldParent = structData.data.parent
                    /* Если у отдела есть родитель, то получаем его и выводим в селекте */
                    if (structData.data.parent) {
                        let parentStruct = await session.get(
                            `/api/v1/structure/${structData.data.parent}/`
                        )
                        structData.data.parent = parentStruct.data.id
                    }
                    this.form = structData.data
                }
                /* Вывод в селекте родителя, если форма открывается через
                 * кнопку "Добавить отдел" под блоком */
                if (this.parent) {
                    this.form.parent = this.parent.id
                }
                this.isModalLoaded = true
            } catch (error) {
                console.error(error)
            }
        }
    }
</script>

<style lang="scss">
    @import '#sass/v-style';

    .modal-change-struct {
        .tooltip {
            z-index: 1000000 !important;
        }
    }

    .v--modal-overlay[data-modal='CreateStructure'] {
        z-index: 1032;
        background-color: rgba(7, 35, 58, 0.5);
        .v--modal-box {
            overflow: visible;
        }
    }
</style>
